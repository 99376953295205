<template>
  <div class="psc-page flex-col">
    <div class="psc-main1 flex-col">
      <div class="psc-main2 flex-col">
        <div class="psc-outer1 flex-col">
          <headInfo :type="type"></headInfo>
          <span class="psc-word5">供应商全生命周期管理</span>
          <span class="psc-word6">询价招标协同、采购执行协同等全覆盖</span>
          <!-- <div class="psc-mod3 flex-col" @click="toNewPage(5)">
            <span class="psc-txt2">立即试用</span>
          </div> -->
        </div>
        <div class="psc-outer2 flex-col">
          <div class="psc-outer3 flex-col"></div>
        </div>
      </div>
      <span class="psc-word7">产品介绍</span>
      <span class="psc-word8"
        >从生产到运输，从供应商到顾客每一个环节，为业务部门提供决策依据，参与助力企业协同合作、需求梳理、高效工作、信息流转等所有难题</span
      >
      <div class="psc-main3 flex-row">
        <div class="psc-main4 flex-col">
          <div class="psc-main4 flex-col justify-between">
            <div class="psc-layer17 flex-col"></div>
            <span class="psc-word9">降本增效</span>
          </div>
        </div>
        <div class="psc-main6 flex-col">
          <div class="psc-main6 flex-col justify-between">
            <img
              class="psc-img24"
              referrerpolicy="no-referrer"
              src="../../assets/images/procedure/scmtwo.png"
            />
            <span class="psc-info2">数据贯通</span>
          </div>
        </div>
        <div class="psc-main7 flex-col">
          <div class="psc-main7 flex-col justify-between">
            <div class="psc-wrap3 flex-col"></div>
            <span class="psc-info3">接口集成</span>
          </div>
        </div>
        <div class="psc-main8 flex-col">
          <div class="psc-main8 flex-col justify-between">
            <div class="psc-layer18 flex-col"></div>
            <span class="psc-word10">信息简化</span>
          </div>
        </div>
      </div>
      <div class="psc-main9 flex-row justify-between">
        <span class="psc-word11"
          >降低企业沟通成本，信息集中管理，增强协同效率及企业效益</span
        >
        <span class="psc-txt3">打通、集成企业每一环节数据流，追溯贯通</span>
        <span class="psc-word12"
          >灵活丰富的集成接口，可无缝快速对接多种系统</span
        >
        <span class="psc-word13"
          >信息精简，通俗易懂，及时反馈，助力业务决策</span
        >
      </div>
      <div class="psc-main10 flex-col">
        <div class="psc-outer5 flex-col">
          <span class="psc-txt4">产品亮点</span>
          <span class="psc-word14"
            >企业通过改善供应链关系，整合信息流、物流、资金流，有效助力企业更具独特竞争优势</span
          >
          <div class="psc-outer6 flex-col"></div>
          <div class="psc-outer7 flex-row justify-between">
            <div class="psc-mod4 flex-col">
              <div class="psc-box1 flex-col">
                <div class="psc-main11 flex-col">
                  <span class="psc-info4">多端支持</span>
                  <span class="psc-paragraph1"
                    >后台管理，所有数据一目了然<br />手机APP，所有订单实时更新</span
                  >
                  <img
                    class="psc-img3"
                    referrerpolicy="no-referrer"
                    src="@/assets/main/productright.png"
                  />
                </div>
              </div>
              <img
                class="psc-pic1"
                referrerpolicy="no-referrer"
                src="@/assets/images/procedure/scmhighone.png"
              />
            </div>
            <div class="psc-mod5 flex-col">
              <div class="psc-bd1 flex-col">
                <div class="psc-box2 flex-col">
                  <span class="psc-word15">订单计划管理</span>
                  <span class="psc-info5"
                    >监控供应链，可以有效、低成本地为顾客递送高质量、高价值的产品或服务</span
                  >
                  <img
                    class="psc-img4"
                    referrerpolicy="no-referrer"
                    src="@/assets/main/productright.png"
                  />
                </div>
              </div>
              <img
                class="psc-pic2"
                referrerpolicy="no-referrer"
                src="@/assets/images/procedure/scmhightwo.png"
              />
            </div>
          </div>
        </div>
        <div class="psc-outer8 flex-col">
          <div class="psc-bd2 flex-col">
            <div class="psc-outer9 flex-col">
              <span class="psc-word16">全报表覆盖</span>
              <span class="psc-paragraph2"
                >多种报表可供参考<br />搭建自动生成报表工具</span
              >
              <img
                class="psc-pic3"
                referrerpolicy="no-referrer"
                src="@/assets/main/productright.png"
              />
            </div>
          </div>
          <img
            class="psc-pic4"
            referrerpolicy="no-referrer"
            src="@/assets/images/procedure/scmhighthird.png"
          />
        </div>
      </div>
      <span class="psc-info6">协同管理系统</span>
      <span class="psc-txt5"
        >基于业务流程的延伸和提供服务的增加，逐步建立供应商评价体系，帮助企业降本增效，助力企业数字化转型</span
      >
      <div class="psc-main12 flex-row">
        <div class="psc-main13 flex-col">
          <div class="psc-mod6 flex-row">
            <span class="psc-word17">采购前管理</span>
          </div>
          <div class="psc-mod7 flex-row justify-between">
            <div class="psc-group18 flex-col"></div>
            <span class="psc-txt6">供应商管理、商品信息管理</span>
          </div>
          <div class="psc-mod8 flex-row justify-between">
            <div class="psc-outer26 flex-col"></div>
            <span class="psc-word18">合同管理、询报价管理</span>
          </div>
          <div class="psc-mod9 flex-row justify-between">
            <div class="psc-group19 flex-col"></div>
            <span class="psc-word19">大量信息一站解决</span>
          </div>
          <div class="psc-main15 flex-col"></div>
        </div>
        <!-- <div class="psc-main15 flex-col"></div> -->
        <div class="psc-main16 flex-col">
          <div class="psc-bd3 flex-row">
            <span class="psc-txt7">采购后支持</span>
          </div>
          <div class="psc-bd4 flex-row justify-between">
            <div class="psc-outer27 flex-col"></div>
            <span class="psc-txt8">系统自动生成对账单</span>
          </div>
          <div class="psc-bd5 flex-row justify-between">
            <div class="psc-mod24 flex-col"></div>
            <span class="psc-info7">格式标准统一，信息准确无误</span>
          </div>
          <div class="psc-main15 flex-col"></div>
        </div>
        <div class="psc-main17 flex-col">
          <div class="psc-main18 flex-row">
            <span class="psc-info8">应用模式</span>
          </div>
          <div class="psc-main19 flex-row justify-between">
            <div class="psc-box7 flex-col"></div>
            <span class="psc-word20">围绕核心企业建设供应链协同体系</span>
          </div>
          <div class="psc-main20 flex-row justify-between">
            <div class="psc-group20 flex-col"></div>
            <span class="psc-word21">完备的系统功能</span>
          </div>
          <div class="psc-main21 flex-row justify-between">
            <div class="psc-box8 flex-col"></div>
            <span class="psc-word22">弹性的流程与功能架构</span>
          </div>
          <div class="psc-main15 flex-col"></div>
        </div>
      </div>
      <div class="psc-main22 flex-col">
        <div class="psc-group1 flex-row">
          <span class="psc-word23">核心功能</span>
        </div>
        <div class="psc-group2 flex-row">
          <div class="psc-mod10 flex-col"></div>
        </div>
        <div class="psc-group3 flex-row justify-between">
          <img
            class="psc-pic22"
            referrerpolicy="no-referrer"
            src="@/assets/images/procedure/scmcoreone.png"
          />
          <img
            class="psc-pic23"
            referrerpolicy="no-referrer"
            src="@/assets/images/procedure/scmcoretwo.png"
          />
          <img
            class="psc-img25"
            referrerpolicy="no-referrer"
            src="@/assets/images/procedure/scmcorethird.png"
          />
          <img
            class="psc-pic24"
            referrerpolicy="no-referrer"
            src="@/assets/images/procedure/scmcorefour.png"
          />
          <img
            class="psc-img26"
            referrerpolicy="no-referrer"
            src="@/assets/images/procedure/scmcorefive.png"
          />
        </div>
        <div class="psc-group4 flex-row">
          <span class="psc-word24">物料分销</span>
          <span class="psc-info9">询价报价</span>
          <span class="psc-info10">供应商管理</span>
          <span class="psc-info11">资源管理</span>
          <span class="psc-info12">库存管理</span>
        </div>
        <div class="psc-group5 flex-row justify-between">
          <span class="psc-word25"
            >优化分销成本，根据生产能力和成本提高客户服务水平</span
          >
          <span class="psc-word26"
            >支持自动、手动拆合包裹，支持各种拆分发货</span
          >
          <span class="psc-txt9"
            >对供应商全方面管控，资质评估、风险性及产品质量等</span
          >
          <span class="psc-txt10"
            >生产消耗原材料、技术和劳动力等，降低成本优化生产</span
          >
          <span class="psc-info13"
            >出入库、盘点、补货&nbsp;、调拨等，及时管理库存</span
          >
        </div>
        <div class="psc-group6 flex-row justify-between">
          <img
            class="psc-pic25"
            referrerpolicy="no-referrer"
            src="@/assets/images/procedure/scmcoresix.png"
          />
          <img
            class="psc-pic26"
            referrerpolicy="no-referrer"
            src="@/assets/images/procedure/scmcoreseven.png"
          />
          <img
            class="psc-pic27"
            referrerpolicy="no-referrer"
            src="@/assets/images/procedure/scmcoreeight.png"
          />
          <img
            class="psc-pic28"
            referrerpolicy="no-referrer"
            src="@/assets/images/procedure/scmcorenine.png"
          />
          <img
            class="psc-pic29"
            referrerpolicy="no-referrer"
            src="@/assets/images/procedure/scmcoreten.png"
          />
        </div>
        <div class="psc-group7 flex-row justify-between">
          <span class="psc-txt11">运输物流</span>
          <span class="psc-word27">采购管理</span>
          <span class="psc-word28">需求计划</span>
          <span class="psc-word29">数据分析</span>
          <span class="psc-txt12">信息预测</span>
        </div>
        <div class="psc-group8 flex-row justify-between">
          <span class="psc-word30">可视化物流运输，订单追踪，预防丢件少件</span>
          <span class="psc-info14">需求预测、战略寻源、供应商绩效管理等</span>
          <span class="psc-info15"
            >利用统计工具、层次分析等手段进行更为精确的预测</span
          >
          <span class="psc-txt13">数据算法分析，协助企业进行战略调整</span>
          <span class="psc-word31">平台信息实时共享，及时掌握最新信息</span>
        </div>
      </div>
      <span class="psc-word32">产品流程</span>
      <span class="psc-txt14">在供应链每一环节，数字化高效管理您的业务</span>
      <div class="psc-main23 flex-col">
        <div class="psc-group9 flex-col">
          <!-- <div class="psc-mod11 flex-row">
            <div class="psc-layer2 flex-col justify-between">
              <img
                class="psc-icon1"
                referrerpolicy="no-referrer"
                src="@/assets/images/procedure/greendot.png"
              />
              <div class="psc-mod12 flex-col"></div>
            </div>
            <div class="psc-layer3 flex-col justify-between">
              <span class="psc-word33">供应商全生命周期管理</span>
              <span class="psc-word34"
                >供应商从潜在，评估，到正式交易及推出的全流程管理</span
              >
            </div>
            <div class="psc-layer4 flex-col justify-between">
              <img
                class="psc-label1"
                referrerpolicy="no-referrer"
                src="@/assets/images/procedure/greendot.png"
              />
              <div class="psc-mod13 flex-col"></div>
            </div>
            <div class="psc-layer5 flex-col justify-between">
              <span class="psc-word35">询价、招标竞价平台</span>
              <span class="psc-word36"
                >供应商的询、招、竞平台，管理每个招标项目，在线报价与投标和在线的比价评标</span
              >
            </div>
            <div class="psc-layer6 flex-col justify-between">
              <img
                class="psc-label2"
                referrerpolicy="no-referrer"
                src="@/assets/images/procedure/greendot.png"
              />
              <div class="psc-outer11 flex-col"></div>
            </div>
            <div class="psc-layer7 flex-col justify-between">
              <span class="psc-word37">及时跟进每笔交期差异</span>
              <span class="psc-info16"
                >自动发布每笔采购订单，实时跟进，及时通知订单的变更与内部同步订单的差异</span
              >
            </div>
            <div class="psc-layer8 flex-col justify-between">
              <img
                class="psc-label3"
                referrerpolicy="no-referrer"
                src="@/assets/images/procedure/greendot.png"
              />
              <div class="psc-section2 flex-col"></div>
            </div>
            <div class="psc-layer9 flex-col justify-between">
              <span class="psc-info17">共享计划，协同制造</span>
              <span class="psc-info18">与供应商同步和共享您的FCST计划</span>
            </div>
          </div>
          <div class="psc-mod14 flex-col">
            <div class="psc-mod19 flex-row">
              <div class="psc-bd9 flex-col justify-between">
                <img
                  class="psc-img11"
                  referrerpolicy="no-referrer"
                  src="@/assets/images/procedure/scmflowone.png"
                />
                <span class="psc-txt15">供应商管理</span>
              </div>
              <img
                class="psc-label7"
                referrerpolicy="no-referrer"
                src="@/assets/images/procedure/greentoarrow.png"
              />
              <div class="psc-bd10 flex-col justify-between">
                <img
                  class="psc-pic9"
                  referrerpolicy="no-referrer"
                  src="@/assets/images/procedure/scmflowtwo.png"
                />
                <span class="psc-txt16">招标竞价</span>
              </div>
              <div class="psc-bd11 flex-col justify-between">
                <img
                  class="psc-img12"
                  referrerpolicy="no-referrer"
                  src="@/assets/images/procedure/scmflowthird.png"
                />
                <span class="psc-txt17">订单跟进</span>
              </div>
              <div class="psc-bd12 flex-col justify-between">
                <img
                  class="psc-img13"
                  referrerpolicy="no-referrer"
                  src="@/assets/images/procedure/scmflowfour.png"
                />
                <span class="psc-word38">计划协同</span>
              </div>
              <img
                class="psc-label8"
                referrerpolicy="no-referrer"
                src="@/assets/images/procedure/greentoarrow.png"
              />
              <img
                class="psc-label9"
                referrerpolicy="no-referrer"
                src="@/assets/images/procedure/greentoarrow.png"
              />
              <img
                class="psc-label10"
                referrerpolicy="no-referrer"
                src="@/assets/images/procedure/greentoarrow.png"
              />
              <div class="psc-bd13 flex-col justify-between">
                <div class="psc-bd14 flex-col"></div>
                <div class="psc-bd15 flex-col"></div>
              </div>
            </div>
            <div class="psc-mod20 flex-row">
              <div class="psc-group17 flex-col"></div>
            </div>
            <div class="psc-mod21 flex-row">
              <img
                class="psc-img14"
                referrerpolicy="no-referrer"
                src="@/assets/images/procedure/scmflowfive.png"
              />
              <img
                class="psc-pic20"
                referrerpolicy="no-referrer"
                src="@/assets/images/procedure/scmflowsix.png"
              />
              <img
                class="psc-pic21"
                referrerpolicy="no-referrer"
                src="@/assets/images/procedure/scmflowseven.png"
              />
              <img
                class="psc-img15"
                referrerpolicy="no-referrer"
                src="@/assets/images/procedure/scmfloweight.png"
              />
            </div>
            <div class="psc-mod22 flex-row">
              <span class="psc-word39">供应商绩效</span>
              <span class="psc-txt27">对账发票</span>
              <span class="psc-txt28">质量协同</span>
              <span class="psc-info19">来料管理</span>
            </div>
            <img
              class="psc-label11"
              referrerpolicy="no-referrer"
              src="@/assets/images/procedure/greentoarrow.png"
            />
            <img
              class="psc-icon7"
              referrerpolicy="no-referrer"
              src="@/assets/images/procedure/greentoarrow.png"
            />
            <img
              class="psc-label12"
              referrerpolicy="no-referrer"
              src="@/assets/images/procedure/greenleftarrow.png"
            />
            <img
              class="psc-label13"
              referrerpolicy="no-referrer"
              src="@/assets/images/procedure/greenleftarrow.png"
            />
            <img
              class="psc-icon8"
              referrerpolicy="no-referrer"
              src="@/assets/images/procedure/greenleftarrow.png"
            />
          </div>
          <div class="psc-mod15 flex-row">
            <img
              class="psc-label4"
              referrerpolicy="no-referrer"
              src="@/assets/images/procedure/greendot.png"
            />
            <span class="psc-word40">评价与优化</span>
            <img
              class="psc-icon4"
              referrerpolicy="no-referrer"
              src="@/assets/images/procedure/greendot.png"
            />
            <span class="psc-word41">大幅提高账务效率</span>
            <img
              class="psc-icon5"
              referrerpolicy="no-referrer"
              src="@/assets/images/procedure/greendot.png"
            />
            <span class="psc-word42">与供应商共同改善质量</span>
            <img
              class="psc-icon6"
              referrerpolicy="no-referrer"
              src="@/assets/images/procedure/greendot.png"
            />
            <span class="psc-word43">精益化管理来料</span>
          </div>
          <div class="psc-mod16 flex-row">
            <span class="psc-info20"
              >自动评价供应商供应绩效与等级，准确发现供应链短板，及时优化</span
            >
            <span class="psc-txt18"
              >自动对账差异分析和来料、退货的费用，在线处理供应商发票和生成应付账务</span
            >
            <span class="psc-txt19"
              >移动化的来料IQC检验，与供应商及时同步质量异常，共同改善和优化</span
            >
            <span class="psc-word44"
              >规范可控供应商每笔来料，及时跟踪送货进度，便捷供应商来料标签和条码打印平台</span
            >
          </div> -->
        </div>
        <!-- <div class="psc-group13 flex-col"></div>
        <div class="psc-group14 flex-col"></div>
        <div class="psc-group15 flex-col"></div>
        <div class="psc-group16 flex-col"></div> -->
      </div>
      <span class="psc-info21">典型案例</span>
      <div class="psc-main24 flex-row justify-between">
        <div class="psc-outer15 flex-col">
          <img
            class="psc-pic10"
            referrerpolicy="no-referrer"
            src="@/assets/images/procedure/scminsone.png"
          />
        </div>
        <div class="psc-outer16 flex-col">
          <span class="psc-info22">深圳吉阳供应链</span>
          <span class="psc-txt20">传统工业型公司，生产制造类别</span>
          <div class="psc-main25 flex-col"></div>
          <span class="psc-word45">痛点：</span>
          <span class="psc-paragraph3"
            >1.招投标投入过大<br />2.无法精准管控供应商送货时间和数量<br />3.物料信息太多，杂乱无章</span
          >
          <span class="psc-word46">解决方案：</span>
          <span class="psc-paragraph4"
            >1.招标上云、报价保密、自动比价，一键发布通告，实时更新进度，分析结果数据<br />2.数据算法，实时追踪，全流程协同<br />3.信息条码化，可自定义模版，省时省力，精准不出错</span
          >
          <div class="psc-main26 flex-row">
            <span class="psc-info23">80%</span>
            <span class="psc-info24">管理报价与招标</span>
            <div class="psc-layer10 flex-col"></div>
            <span class="psc-txt21">-30%</span>
            <span class="psc-word47">缺料风险</span>
            <div class="psc-layer11 flex-col"></div>
          </div>
          <button class="psc-main27 flex-col" @click="jumpPage('instance','header-ident')">
            <span class="psc-word48">查看详情</span>
          </button>
        </div>
      </div>
      <secFoot />
      <footInfo />
    </div>
    <div class="psc-main35 flex-col">
      <div class="psc-section3 flex-row">
        <span class="psc-word67">采购协同</span>
      </div>
      <div class="psc-section4 flex-row justify-between">
        <div class="psc-block7 flex-col"></div>
        <span class="psc-word68">常用沟通工具与平台直联</span>
      </div>
      <div class="psc-section5 flex-row justify-between">
        <div class="psc-bd16 flex-col"></div>
        <span class="psc-txt26">信息在平台内流转并关联到业务</span>
      </div>
      <div class="psc-section6 flex-row justify-between">
        <div class="psc-outer28 flex-col"></div>
        <span class="psc-info31">及时反馈物流状态以及节点</span>
      </div>
      <div class="psc-main15 flex-col"></div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      type: 1,
      constants: {},
    };
  },
  methods: {
    jumpPage(path,id) {
      var path=path
      var Id=id;
      localStorage.setItem('toId',Id);
      this.$router.push({
        name: path,
        params: {
          sel: 'third'
        }
      });
    },
    // 跳转页面
    toNewPage(index) {
      let url = "";
      // index的类型 0-主页 1-产品介绍 2-服务体系 3-典型案例 4-关于松云 5-预约注册
      switch (index) {
        case 0: //主页
          url = "/";
          break;
        case 1: // 产品介绍
          url = "/product/erp";
          break;
        case 2: // 服务体系
          url = "/system";
          break;
        case 3: //  典型案例
          url = "/instance/erp";
          break;
        case 4: //关于松云
          url = "/about";
          break;
        case 5: //预约注册
          url = "/register";
          break;
      }
      if (url == "") {
        return;
      }
      this.$router.push({ path: url });
    },
  },
};
</script>
<style rel="stylesheet/scss" lang="scss" >
.psc-page {
  position: relative;
  width: 1920px;
  height: 7679px;
  margin: 0 auto;
  background-color: rgba(250, 250, 250, 1);
  overflow: hidden;
  .psc-main1 {
    width: 1920px;
    height: 7679px;
    .psc-main2 {
      // z-index: 3;
      height: 920px;
      overflow: hidden;
      background: url(../../assets/images/back/graythird.png)
        100% no-repeat;
      width: 1920px;
      position: relative;
      .psc-outer1 {
        z-index: 80;
        width: 1920px;
        height: 610px;
        .psc-mod1 {
          height: 100px;
          width: 1920px;
          .psc-wrap1 {
            width: 1775px;
            height: 42px;
            margin: 29px 0 0 65px;
            .psc-img1 {
              width: 180px;
              height: 38px;
              margin-top: 2px;
            }
            .psc-word1 {
              width: 43px;
              height: 29px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 0.88);
              font-size: 21px;
              letter-spacing: 0.41999998688697815px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 29px;
              text-align: left;
              margin: 7px 0 0 301px;
            }
            .psc-txt1 {
              width: 85px;
              height: 29px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 21px;
              letter-spacing: 0.41999998688697815px;
              font-family: AlibabaPuHuiTiB;
              white-space: nowrap;
              line-height: 29px;
              text-align: left;
              margin: 7px 0 0 104px;
            }
            .psc-word2 {
              width: 85px;
              height: 29px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 0.88);
              font-size: 21px;
              letter-spacing: 0.41999998688697815px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 29px;
              text-align: left;
              margin: 7px 0 0 104px;
            }
            .psc-word3 {
              width: 85px;
              height: 29px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 0.88);
              font-size: 21px;
              letter-spacing: 0.41999998688697815px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 29px;
              text-align: left;
              margin: 7px 0 0 104px;
            }
            .psc-word4 {
              width: 85px;
              height: 29px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 0.88);
              font-size: 21px;
              letter-spacing: 0.41999998688697815px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 29px;
              text-align: left;
              margin: 7px 0 0 104px;
            }
            .psc-mod2 {
              height: 42px;
              border-radius: 21px;
              border: 2px solid rgba(37, 184, 47, 1);
              margin-left: 346px;
              width: 149px;
              .psc-info1 {
                width: 85px;
                height: 29px;
                display: block;
                overflow-wrap: break-word;
                color: rgba(255, 255, 255, 0.94);
                font-size: 21px;
                letter-spacing: 0.41999998688697815px;
                font-family: AlibabaPuHuiTiR;
                white-space: nowrap;
                line-height: 29px;
                text-align: left;
                margin: 7px 0 0 32px;
              }
            }
          }
        }
        .psc-word5 {
          width: 483px;
          height: 65px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 48px;
          letter-spacing: 1px;
          font-family: AlibabaPuHuiTiM;
          white-space: nowrap;
          line-height: 65px;
          text-align: left;
          margin: 221px 0 0 719px;
        }
        .psc-word6 {
          width: 499px;
          height: 38px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 28px;
          letter-spacing: 1.75px;
          font-family: AlibabaPuHuiTiR;
          white-space: nowrap;
          line-height: 38px;
          text-align: center;
          margin: 36px 0 0 711px;
        }
        .psc-mod3 {
          height: 68px;
          border-radius: 34px;
          background-color: rgba(37, 184, 47, 1);
          box-shadow: 0px 20px 40px 0px rgba(37, 184, 47, 0.32);
          width: 220px;
          margin: 82px 0 0 850px;
          .psc-txt2 {
            width: 113px;
            height: 38px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 28px;
            letter-spacing: 0.5600000023841858px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 38px;
            text-align: left;
            margin: 15px 0 0 54px;
          }
        }
      }
      .psc-outer2 {
        z-index: 4;
        height: 920px;
        background: url(../../assets/main/scm.png)
          100% no-repeat;
        width: 1920px;
        position: absolute;
        left: 0;
        top: 0;
        .psc-outer3 {
          width: 1920px;
          height: 920px;
          // background-color: rgba(0, 0, 0, 0.5);
        }
      }
    }
    .psc-word7 {
      width: 129px;
      height: 44px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(39, 39, 39, 1);
      font-size: 32px;
      letter-spacing: 0.6399999856948853px;
      font-family: AlibabaPuHuiTiM;
      white-space: nowrap;
      line-height: 44px;
      text-align: left;
      margin: 120px 0 0 895px;
    }
    .psc-word8 {
      width: 898px;
      height: 64px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(102, 102, 102, 1);
      font-size: 16px;
      letter-spacing: 1.3333333730697632px;
      font-family: AlibabaPuHuiTiR;
      line-height: 32px;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 24px 0 0 510px;
    }
    .psc-main3 {
      width: 1209px;
      height: 210px;
      margin: 79px 0 0 357px;
      .psc-main4 {
        width: 130px;
        height: 199px;
        margin-top: 11px;
        .psc-main4 {
          width: 130px;
          height: 199px;
          margin-top: 11px;
          .psc-layer17 {
            width: 130px;
            height: 116px;
            background: url(../../assets/images/procedure/scmone.png) -2px -2px
              no-repeat;
          }
          .psc-word9 {
            width: 85px;
            height: 29px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(39, 39, 39, 1);
            font-size: 21px;
            letter-spacing: 0.41999998688697815px;
            font-family: AlibabaPuHuiTiR;
            white-space: nowrap;
            line-height: 29px;
            text-align: left;
            margin: 54px 0 0 21px;
          }
        }
      }
      .psc-main6 {
        width: 137px;
        height: 208px;
        margin: 2px 0 0 227px;
        .psc-main6 {
          width: 137px;
          height: 208px;
          margin: 2px 0 0 0;
          .psc-img24 {
            width: 137px;
            height: 131px;
          }
          .psc-info2 {
            width: 85px;
            height: 29px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(39, 39, 39, 1);
            font-size: 21px;
            letter-spacing: 0.41999998688697815px;
            font-family: AlibabaPuHuiTiR;
            white-space: nowrap;
            line-height: 29px;
            text-align: left;
            margin: 48px 0 0 24px;
          }
        }
      }
      .psc-main7 {
        width: 140px;
        height: 210px;
        margin-left: 221px;
        .psc-main7 {
          width: 140px;
          height: 210px;
          margin-left: 0;
          .psc-wrap3 {
            width: 140px;
            height: 140px;
            background: url(../../assets/images/procedure/scmthird.png) -2px -2px
              no-repeat;
          }
          .psc-info3 {
            width: 85px;
            height: 29px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(39, 39, 39, 1);
            font-size: 21px;
            letter-spacing: 0.41999998688697815px;
            font-family: AlibabaPuHuiTiR;
            white-space: nowrap;
            line-height: 29px;
            text-align: center;
            margin: 41px 0 0 26px;
          }
        }
      }
      .psc-main8 {
        width: 130px;
        height: 201px;
        margin: 9px 0 0 224px;
        .psc-main8 {
          width: 130px;
          height: 201px;
          margin: 9px 0 0 0;
          .psc-layer18 {
            width: 130px;
            height: 121px;
            background: url(../../assets/images/procedure/scmfour.png) -1px
              0px no-repeat;
          }
          .psc-word10 {
            width: 85px;
            height: 29px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(39, 39, 39, 1);
            font-size: 21px;
            letter-spacing: 0.41999998688697815px;
            font-family: AlibabaPuHuiTiR;
            white-space: nowrap;
            line-height: 29px;
            text-align: left;
            margin: 51px 0 0 22px;
          }
        }
      }
    }
    .psc-main9 {
      width: 1325px;
      height: 56px;
      margin: 16px 0 0 298px;
      .psc-word11 {
        width: 245px;
        height: 56px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(102, 102, 102, 1);
        font-size: 14px;
        letter-spacing: 1.1666666269302368px;
        font-family: AlibabaPuHuiTiR;
        line-height: 28px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .psc-txt3 {
        width: 245px;
        height: 56px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(102, 102, 102, 1);
        font-size: 14px;
        letter-spacing: 1.1666666269302368px;
        font-family: AlibabaPuHuiTiR;
        line-height: 28px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .psc-word12 {
        width: 245px;
        height: 56px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(102, 102, 102, 1);
        font-size: 14px;
        letter-spacing: 1.1666666269302368px;
        font-family: AlibabaPuHuiTiR;
        line-height: 28px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .psc-word13 {
        width: 245px;
        height: 56px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(102, 102, 102, 1);
        font-size: 14px;
        letter-spacing: 1.1666666269302368px;
        font-family: AlibabaPuHuiTiR;
        line-height: 28px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .psc-main10 {
      // z-index: 84;
      height: 946px;
      background-color: rgba(37, 38, 37, 1);
      margin-top: 120px;
      width: 1920px;
      position: relative;
      .psc-outer5 {
        width: 1403px;
        height: 810px;
        margin-top: 68px;
        .psc-txt4 {
          width: 129px;
          height: 44px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 32px;
          letter-spacing: 0.6399999856948853px;
          font-family: AlibabaPuHuiTiM;
          white-space: nowrap;
          line-height: 44px;
          text-align: left;
          margin-left: 895px;
        }
        .psc-word14 {
          width: 750px;
          height: 32px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 18px;
          letter-spacing: 1.5px;
          font-family: AlibabaPuHuiTiR;
          white-space: nowrap;
          line-height: 32px;
          text-align: center;
          margin: 24px 0 0 585px;
        }
        .psc-outer6 {
          width: 84px;
          height: 6px;
          background-color: rgba(37, 184, 47, 1);
          margin: 12px 0 0 918px;
        }
        .psc-outer7 {
          width: 1403px;
          height: 644px;
          margin-top: 48px;
          .psc-mod4 {
            height: 644px;
            overflow: hidden;
            // background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng58b10c9a7fb637906f4ef4238b50a855959ec0214d519c0a43076fd50b7169d7)
            //   100% no-repeat;
            width: 883px;
            position: relative;
            .psc-box1 {
              z-index: 2;
              height: 644px;
              overflow: hidden;
              background: url(../../assets/images/back/scmgreyone.png)
                100% no-repeat;
              width: 883px;
              position: relative;
              .psc-main11 {
                width: 245px;
                height: 257px;
                margin: 248px 0 0 199px;
                .psc-info4 {
                  width: 145px;
                  height: 50px;
                  display: block;
                  overflow-wrap: break-word;
                  color: rgba(255, 255, 255, 1);
                  font-size: 36px;
                  letter-spacing: 0.7200000286102295px;
                  font-family: AlibabaPuHuiTiM;
                  white-space: nowrap;
                  line-height: 50px;
                  text-align: left;
                }
                .psc-paragraph1 {
                  width: 245px;
                  height: 56px;
                  display: block;
                  overflow-wrap: break-word;
                  color: rgba(255, 255, 255, 1);
                  font-size: 16px;
                  letter-spacing: 1.5px;
                  font-family: AlibabaPuHuiTiM;
                  line-height: 28px;
                  text-align: left;
                  margin-top: 16px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
                .psc-img3 {
                  width: 55px;
                  height: 55px;
                  margin: 80px 0 0 2px;
                }
              }
            }
            .psc-pic1 {
              position: absolute;
              left: 0;
              top: 0;
              width: 883px;
              height: 644px;
            }
          }
          .psc-mod5 {
            height: 644px;
            overflow: hidden;
            // background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng0f5726f0f350738196c36ea66a8a5d6215bca306970ea80462c7ea2db7512a35)
            //   100% no-repeat;
            width: 520px;
            position: relative;
            .psc-bd1 {
              z-index: 2;
              height: 644px;
              overflow: hidden;
              background: url(../../assets/images/back/scmgreytwo.png)
                100% no-repeat;
              width: 518px;
              position: relative;
              .psc-box2 {
                width: 246px;
                height: 257px;
                margin: 248px 0 0 119px;
                .psc-word15 {
                  width: 217px;
                  height: 50px;
                  display: block;
                  overflow-wrap: break-word;
                  color: rgba(255, 255, 255, 1);
                  font-size: 36px;
                  letter-spacing: 0.7200000286102295px;
                  font-family: AlibabaPuHuiTiM;
                  white-space: nowrap;
                  line-height: 50px;
                  text-align: left;
                  margin-left: 1px;
                }
                .psc-info5 {
                  width: 245px;
                  height: 84px;
                  display: block;
                  overflow-wrap: break-word;
                  color: rgba(255, 255, 255, 1);
                  font-size: 16px;
                  letter-spacing: 1.5px;
                  font-family: AlibabaPuHuiTiM;
                  line-height: 28px;
                  text-align: left;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  margin: 16px 0 0 1px;
                }
                .psc-img4 {
                  width: 55px;
                  height: 55px;
                  margin-top: 52px;
                }
              }
            }
            .psc-pic2 {
              position: absolute;
              left: 0;
              top: 0;
              width: 520px;
              height: 644px;
            }
          }
        }
      }
      .psc-outer8 {
        z-index: 1;
        height: 644px;
        overflow: hidden;
        // background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng323b6a13e922a5205aa30356b26c38866f87a179809ed6ee3f844760bebde001)
        //   100% no-repeat;
        width: 519px;
        position: absolute;
        left: 1401px;
        top: 234px;
        .psc-bd2 {
          z-index: 2;
          height: 644px;
          overflow: hidden;
          background: url(../../assets/images/back/scmgreythird.png)
            100% no-repeat;
          width: 519px;
          position: relative;
          .psc-outer9 {
            width: 245px;
            height: 257px;
            margin: 248px 0 0 120px;
            .psc-word16 {
              width: 181px;
              height: 50px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 36px;
              letter-spacing: 0.7200000286102295px;
              font-family: AlibabaPuHuiTiM;
              white-space: nowrap;
              line-height: 50px;
              text-align: left;
            }
            .psc-paragraph2 {
              width: 245px;
              height: 56px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 1.5px;
              font-family: AlibabaPuHuiTiM;
              line-height: 28px;
              text-align: left;
              margin-top: 16px;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .psc-pic3 {
              width: 55px;
              height: 55px;
              margin-top: 80px;
            }
          }
        }
        .psc-pic4 {
          // z-index: 178;
          position: absolute;
          left: 0;
          top: 0;
          width: 519px;
          height: 644px;
        }
      }
    }
    .psc-info6 {
      width: 193px;
      height: 44px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(40, 40, 40, 1);
      font-size: 32px;
      letter-spacing: 0.6399999856948853px;
      font-family: AlibabaPuHuiTiM;
      white-space: nowrap;
      line-height: 44px;
      text-align: center;
      margin: 120px 0 0 855px;
    }
    .psc-txt5 {
      width: 898px;
      height: 64px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(102, 102, 102, 1);
      font-size: 18px;
      letter-spacing: 1.5px;
      font-family: AlibabaPuHuiTiR;
      line-height: 32px;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 24px 0 0 511px;
    }
    .psc-main12 {
      width: 1520px;
      height: 408px;
      margin: 52px 0 0 201px;
      .psc-main13 {
        height: 396px;
        border: 1px solid rgba(245, 245, 245, 1);
        background-color: rgba(255, 255, 255, 1);
        box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.06);
        width: 359px;
        .psc-mod6 {
          width: 121px;
          height: 33px;
          margin: 92px 0 0 119px;
          .psc-word17 {
            width: 121px;
            height: 33px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(40, 40, 40, 1);
            font-size: 24px;
            letter-spacing: 0.47999998927116394px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 33px;
            text-align: center;
          }
        }
        .psc-mod7 {
          width: 235px;
          height: 28px;
          margin: 36px 0 0 64px;
          .psc-group18 {
            width: 21px;
            height: 21px;
            background: url(../../assets/images/procedure/tick.png)
              0px 0px no-repeat;
            margin-top: 4px;
          }
          .psc-txt6 {
            width: 205px;
            height: 28px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(102, 102, 102, 1);
            font-size: 16px;
            letter-spacing: 1.3333333730697632px;
            font-family: AlibabaPuHuiTiR;
            white-space: nowrap;
            line-height: 28px;
            text-align: center;
          }
        }
        .psc-mod8 {
          width: 201px;
          height: 28px;
          margin: 24px 0 0 64px;
          .psc-outer26 {
            width: 21px;
            height: 22px;
            background: url(../../assets/images/procedure/tick.png)
              0px 0px no-repeat;
            margin-top: 4px;
          }
          .psc-word18 {
            width: 171px;
            height: 28px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(102, 102, 102, 1);
            font-size: 16px;
            letter-spacing: 1.3333333730697632px;
            font-family: AlibabaPuHuiTiR;
            white-space: nowrap;
            line-height: 28px;
            text-align: center;
          }
        }
        .psc-mod9 {
          width: 167px;
          height: 28px;
          margin: 24px 0 103px 64px;
          .psc-group19 {
            width: 21px;
            height: 21px;
            background: url(../../assets/images/procedure/tick.png)
              0px 0px no-repeat;
            margin-top: 4px;
          }
          .psc-word19 {
            width: 137px;
            height: 28px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(102, 102, 102, 1);
            font-size: 16px;
            letter-spacing: 1.3333333730697632px;
            font-family: AlibabaPuHuiTiR;
            white-space: nowrap;
            line-height: 28px;
            text-align: center;
          }
        }
      }
      .psc-main13:hover {
        background-color: rgba(37, 184, 47, 1);
        box-shadow: 0px 20px 40px 0px rgba(37, 184, 47, 0.24);
        .psc-word17 {
          color: rgba(255, 255, 255, 1);
        }
        .psc-txt6,
        .psc-word18,
        .psc-word19 {
          color: rgba(255, 255, 255, 1);
        }
        .psc-group18,
        .psc-outer26,
        .psc-group19 {
          background: url(../../assets/images/procedure/whitetick.png)
            0px 0px no-repeat;
        }
        .psc-main15 {
          width: 259px;
          height: 165px;
          background: url(../../assets/images/back/greencloth.png) 100%
            no-repeat;
          margin: -83px 0 0 56px;
          // margin: -12px 0 0 113px;
        }
      }
      .psc-main16 {
        height: 396px;
        border: 1px solid rgba(245, 245, 245, 1);
        background-color: rgba(255, 255, 255, 1);
        box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.06);
        margin-left: 416px;
        width: 358px;
        .psc-bd3 {
          width: 121px;
          height: 33px;
          margin: 92px 0 0 119px;
          .psc-txt7 {
            width: 121px;
            height: 33px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(40, 40, 40, 1);
            font-size: 24px;
            letter-spacing: 0.47999998927116394px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 33px;
            text-align: center;
          }
        }
        .psc-bd4 {
          width: 184px;
          height: 28px;
          margin: 36px 0 0 62px;
          .psc-outer27 {
            width: 21px;
            height: 21px;
            background: url(../../assets/images/procedure/tick.png)
              0px 0px no-repeat;
            margin-top: 4px;
          }
          .psc-txt8 {
            width: 154px;
            height: 28px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(102, 102, 102, 1);
            font-size: 16px;
            letter-spacing: 1.3333333730697632px;
            font-family: AlibabaPuHuiTiR;
            white-space: nowrap;
            line-height: 28px;
            text-align: center;
          }
        }
        .psc-bd5 {
          width: 253px;
          height: 28px;
          margin: 24px 0 155px 62px;
          .psc-mod24 {
            width: 21px;
            height: 22px;
            background: url(../../assets/images/procedure/tick.png)
              0px 0px no-repeat;
            margin-top: 4px;
          }
          .psc-info7 {
            width: 223px;
            height: 28px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(102, 102, 102, 1);
            font-size: 16px;
            letter-spacing: 1.3333333730697632px;
            font-family: AlibabaPuHuiTiR;
            white-space: nowrap;
            line-height: 28px;
            text-align: center;
          }
        }
      }
      .psc-main16:hover {
        background-color: rgba(37, 184, 47, 1);
        box-shadow: 0px 20px 40px 0px rgba(37, 184, 47, 0.24);
        .psc-txt7 {
          color: rgba(255, 255, 255, 1);
        }
        .psc-txt8,
        .psc-info7 {
          color: rgba(255, 255, 255, 1);
        }
        .psc-outer27,
        .psc-mod24 {
          background: url(../../assets/images/procedure/whitetick.png)
            0px 0px no-repeat;
        }
        .psc-main15 {
          width: 259px;
          height: 165px;
          background: url(../../assets/images/back/greencloth.png) 100%
            no-repeat;
          margin: -83px 0 0 56px;
          // margin: -12px 0 0 113px;
        }
      }
      .psc-main17 {
        height: 396px;
        border: 1px solid rgba(245, 245, 245, 1);
        background-color: rgba(255, 255, 255, 1);
        box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.06);
        margin-left: 29px;
        width: 358px;
        .psc-main18 {
          width: 97px;
          height: 33px;
          margin: 92px 0 0 131px;
          .psc-info8 {
            width: 97px;
            height: 33px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(40, 40, 40, 1);
            font-size: 24px;
            letter-spacing: 0.47999998927116394px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 33px;
            text-align: center;
          }
        }
        .psc-main19 {
          width: 287px;
          height: 28px;
          margin: 36px 0 0 40px;
          .psc-box7 {
            width: 21px;
            height: 21px;
            background: url(../../assets/images/procedure/tick.png)
              0px 0px no-repeat;
            margin-top: 4px;
          }
          .psc-word20 {
            width: 257px;
            height: 28px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(102, 102, 102, 1);
            font-size: 16px;
            letter-spacing: 1.3333333730697632px;
            font-family: AlibabaPuHuiTiR;
            white-space: nowrap;
            line-height: 28px;
            text-align: center;
          }
        }
        .psc-main20 {
          width: 150px;
          height: 28px;
          margin: 24px 0 0 40px;
          .psc-group20 {
            width: 21px;
            height: 22px;
            background: url(../../assets/images/procedure/tick.png)
              0px 0px no-repeat;
            margin-top: 4px;
          }
          .psc-word21 {
            width: 120px;
            height: 28px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(102, 102, 102, 1);
            font-size: 16px;
            letter-spacing: 1.3333333730697632px;
            font-family: AlibabaPuHuiTiR;
            white-space: nowrap;
            line-height: 28px;
            text-align: center;
          }
        }
        .psc-main21 {
          width: 201px;
          height: 28px;
          margin: 24px 0 103px 40px;
          .psc-box8 {
            width: 21px;
            height: 22px;
            background: url(../../assets/images/procedure/tick.png)
              0px 0px no-repeat;
            margin-top: 4px;
          }
          .psc-word22 {
            width: 171px;
            height: 28px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(102, 102, 102, 1);
            font-size: 16px;
            letter-spacing: 1.3333333730697632px;
            font-family: AlibabaPuHuiTiR;
            white-space: nowrap;
            line-height: 28px;
            text-align: center;
          }
        }
      }
      .psc-main17:hover {
        background-color: rgba(37, 184, 47, 1);
        box-shadow: 0px 20px 40px 0px rgba(37, 184, 47, 0.24);
        .psc-info8 {
          color: rgba(255, 255, 255, 1);
        }
        .psc-word20,
        .psc-word21,
        .psc-word22 {
          color: rgba(255, 255, 255, 1);
        }
        .psc-box7,
        .psc-group20,
        .psc-box8 {
          background: url(../../assets/images/procedure/whitetick.png)
            0px 0px no-repeat;
        }
        .psc-main15 {
          width: 259px;
          height: 165px;
          background: url(../../assets/images/back/greencloth.png) 100%
            no-repeat;
          margin: -83px 0 0 56px;
        }
      }
    }
    .psc-main22 {
      height: 838px;
      background-color: rgba(37, 184, 47, 1);
      margin-top: 108px;
      width: 1920px;
      .psc-group1 {
        width: 129px;
        height: 44px;
        margin: 80px 0 0 896px;
        .psc-word23 {
          width: 129px;
          height: 44px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 32px;
          letter-spacing: 0.6399999856948853px;
          font-family: AlibabaPuHuiTiM;
          white-space: nowrap;
          line-height: 44px;
          text-align: left;
        }
      }
      .psc-group2 {
        width: 84px;
        height: 6px;
        margin: 24px 0 0 918px;
        .psc-mod10 {
          width: 84px;
          height: 6px;
          background-color: rgba(255, 255, 255, 1);
        }
      }
      .psc-group3 {
        width: 1111px;
        height: 120px;
        margin: 76px 0 0 403px;
        .psc-pic22 {
          width: 120px;
          height: 120px;
        }
        .psc-pic23 {
          width: 120px;
          height: 120px;
        }
        .psc-img25 {
          width: 120px;
          height: 120px;
        }
        .psc-pic24 {
          width: 120px;
          height: 120px;
        }
        .psc-img26 {
          width: 120px;
          height: 120px;
        }
      }
      .psc-group4 {
        width: 1060px;
        height: 28px;
        margin: 20px 0 0 429px;
        .psc-word24 {
          width: 69px;
          height: 28px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 16px;
          letter-spacing: 1.3333333730697632px;
          font-family: AlibabaPuHuiTiM;
          white-space: nowrap;
          line-height: 28px;
          text-align: center;
        }
        .psc-info9 {
          width: 69px;
          height: 28px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 16px;
          letter-spacing: 1.3333333730697632px;
          font-family: AlibabaPuHuiTiM;
          white-space: nowrap;
          line-height: 28px;
          text-align: center;
          margin-left: 179px;
        }
        .psc-info10 {
          width: 86px;
          height: 28px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 16px;
          letter-spacing: 1.3333333730697632px;
          font-family: AlibabaPuHuiTiM;
          white-space: nowrap;
          line-height: 28px;
          text-align: center;
          margin-left: 169px;
        }
        .psc-info11 {
          width: 69px;
          height: 28px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 16px;
          letter-spacing: 1.3333333730697632px;
          font-family: AlibabaPuHuiTiM;
          white-space: nowrap;
          line-height: 28px;
          text-align: center;
          margin-left: 171px;
        }
        .psc-info12 {
          width: 69px;
          height: 28px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 16px;
          letter-spacing: 1.3333333730697632px;
          font-family: AlibabaPuHuiTiM;
          white-space: nowrap;
          line-height: 28px;
          text-align: center;
          margin-left: 179px;
        }
      }
      .psc-group5 {
        width: 1179px;
        height: 44px;
        margin: 4px 0 0 369px;
        .psc-word25 {
          width: 188px;
          height: 44px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 14px;
          letter-spacing: 1.1666666269302368px;
          font-family: AlibabaPuHuiTiR;
          line-height: 22px;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .psc-word26 {
          width: 188px;
          height: 44px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 14px;
          letter-spacing: 1.1666666269302368px;
          font-family: AlibabaPuHuiTiR;
          line-height: 22px;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .psc-txt9 {
          width: 188px;
          height: 44px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 14px;
          letter-spacing: 1.1666666269302368px;
          font-family: AlibabaPuHuiTiR;
          line-height: 22px;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .psc-txt10 {
          width: 188px;
          height: 44px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 14px;
          letter-spacing: 1.1666666269302368px;
          font-family: AlibabaPuHuiTiR;
          line-height: 22px;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .psc-info13 {
          width: 188px;
          height: 44px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 14px;
          letter-spacing: 1.1666666269302368px;
          font-family: AlibabaPuHuiTiR;
          line-height: 22px;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .psc-group6 {
        width: 1111px;
        height: 120px;
        margin: 54px 0 0 403px;
        .psc-pic25 {
          width: 120px;
          height: 120px;
        }
        .psc-pic26 {
          width: 120px;
          height: 120px;
        }
        .psc-pic27 {
          width: 120px;
          height: 120px;
        }
        .psc-pic28 {
          width: 120px;
          height: 120px;
        }
        .psc-pic29 {
          width: 120px;
          height: 120px;
        }
      }
      .psc-group7 {
        width: 1060px;
        height: 28px;
        margin: 20px 0 0 429px;
        .psc-txt11 {
          width: 69px;
          height: 28px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 16px;
          letter-spacing: 1.3333333730697632px;
          font-family: AlibabaPuHuiTiM;
          white-space: nowrap;
          line-height: 28px;
          text-align: center;
        }
        .psc-word27 {
          width: 69px;
          height: 28px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 16px;
          letter-spacing: 1.3333333730697632px;
          font-family: AlibabaPuHuiTiM;
          white-space: nowrap;
          line-height: 28px;
          text-align: center;
        }
        .psc-word28 {
          width: 69px;
          height: 28px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 16px;
          letter-spacing: 1.3333333730697632px;
          font-family: AlibabaPuHuiTiM;
          white-space: nowrap;
          line-height: 28px;
          text-align: center;
        }
        .psc-word29 {
          width: 69px;
          height: 28px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 16px;
          letter-spacing: 1.3333333730697632px;
          font-family: AlibabaPuHuiTiM;
          white-space: nowrap;
          line-height: 28px;
          text-align: center;
        }
        .psc-txt12 {
          width: 69px;
          height: 28px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 16px;
          letter-spacing: 1.3333333730697632px;
          font-family: AlibabaPuHuiTiM;
          white-space: nowrap;
          line-height: 28px;
          text-align: center;
        }
      }
      .psc-group8 {
        width: 1180px;
        height: 44px;
        margin: 4px 0 122px 369px;
        .psc-word30 {
          width: 188px;
          height: 44px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 14px;
          letter-spacing: 1.1666666269302368px;
          font-family: AlibabaPuHuiTiR;
          line-height: 22px;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .psc-info14 {
          width: 188px;
          height: 44px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 14px;
          letter-spacing: 1.1666666269302368px;
          font-family: AlibabaPuHuiTiR;
          line-height: 22px;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .psc-info15 {
          width: 188px;
          height: 44px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 14px;
          letter-spacing: 1.1666666269302368px;
          font-family: AlibabaPuHuiTiR;
          line-height: 22px;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .psc-txt13 {
          width: 188px;
          height: 44px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 14px;
          letter-spacing: 1.1666666269302368px;
          font-family: AlibabaPuHuiTiR;
          line-height: 22px;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .psc-word31 {
          width: 188px;
          height: 44px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 14px;
          letter-spacing: 1.1666666269302368px;
          font-family: AlibabaPuHuiTiR;
          line-height: 22px;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    .psc-word32 {
      width: 134px;
      height: 44px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(40, 40, 40, 1);
      font-size: 32px;
      letter-spacing: 2px;
      font-family: AlibabaPuHuiTiM;
      white-space: nowrap;
      line-height: 44px;
      text-align: left;
      margin: 120px 0 0 893px;
    }
    .psc-txt14 {
      width: 898px;
      height: 32px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(102, 102, 102, 1);
      font-size: 18px;
      letter-spacing: 1.5px;
      font-family: AlibabaPuHuiTiR;
      white-space: nowrap;
      line-height: 32px;
      text-align: center;
      margin: 24px 0 0 511px;
    }
    .psc-main23 {
      // z-index: 289;
      height: 822px;
      background-color: rgba(255, 255, 255, 1);
      margin-top: 36px;
      width: 1920px;
      position: relative;
      .psc-group9 {
        width: 1920px;
        height: 702px;
        margin-top: 60px;
        background: url(../../assets/images/procedure/scmflow.png) 
          100% no-repeat;
        .psc-mod11 {
          width: 1198px;
          height: 130px;
          margin-left: 236px;
          .psc-layer2 {
            width: 16px;
            height: 126px;
            margin-top: 4px;
            .psc-icon1 {
              width: 16px;
              height: 16px;
            }
            .psc-mod12 {
              width: 1px;
              height: 110px;
              background: url(../../assets/images/procedure/greendaline.png)
                0px 0px no-repeat;
              margin-left: 8px;
            }
          }
          .psc-layer3 {
            width: 200px;
            height: 76px;
            margin-left: 16px;
            .psc-word33 {
              width: 171px;
              height: 24px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(40, 40, 40, 1);
              font-size: 16px;
              letter-spacing: 1.3333333730697632px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 24px;
              text-align: left;
            }
            .psc-word34 {
              width: 200px;
              height: 48px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(153, 153, 153, 1);
              font-size: 14px;
              letter-spacing: 1.1666666269302368px;
              font-family: AlibabaPuHuiTiR;
              line-height: 24px;
              text-align: left;
              margin-top: 4px;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
          .psc-layer4 {
            width: 16px;
            height: 126px;
            margin: 4px 0 0 90px;
            .psc-label1 {
              width: 16px;
              height: 16px;
            }
            .psc-mod13 {
              width: 1px;
              height: 110px;
              background: url(../../assets/images/procedure/greendaline.png)
                0px 0px no-repeat;
              margin-left: 8px;
            }
          }
          .psc-layer5 {
            width: 200px;
            height: 100px;
            margin-left: 16px;
            .psc-word35 {
              width: 154px;
              height: 24px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(40, 40, 40, 1);
              font-size: 16px;
              letter-spacing: 1.3333333730697632px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 24px;
              text-align: left;
            }
            .psc-word36 {
              width: 200px;
              height: 72px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(153, 153, 153, 1);
              font-size: 14px;
              letter-spacing: 1.1666666269302368px;
              font-family: AlibabaPuHuiTiR;
              line-height: 24px;
              text-align: left;
              margin-top: 4px;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
          .psc-layer6 {
            width: 16px;
            height: 126px;
            margin: 4px 0 0 90px;
            .psc-label2 {
              width: 16px;
              height: 16px;
            }
            .psc-outer11 {
              width: 1px;
              height: 110px;
              background: url(../../assets/images/procedure/greendaline.png)
                0px 0px no-repeat;
              margin-left: 8px;
            }
          }
          .psc-layer7 {
            width: 200px;
            height: 100px;
            margin-left: 16px;
            .psc-word37 {
              width: 171px;
              height: 24px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(40, 40, 40, 1);
              font-size: 16px;
              letter-spacing: 1.3333333730697632px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 24px;
              text-align: left;
            }
            .psc-info16 {
              width: 200px;
              height: 72px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(153, 153, 153, 1);
              font-size: 14px;
              letter-spacing: 1.1666666269302368px;
              font-family: AlibabaPuHuiTiR;
              line-height: 24px;
              text-align: left;
              margin-top: 4px;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
          .psc-layer8 {
            width: 16px;
            height: 126px;
            margin: 4px 0 0 90px;
            .psc-label3 {
              width: 16px;
              height: 16px;
            }
            .psc-section2 {
              width: 1px;
              height: 110px;
              background: url(../../assets/images/procedure/greendaline.png)
                0px 0px no-repeat;
              margin-left: 8px;
            }
          }
          .psc-layer9 {
            width: 200px;
            height: 76px;
            margin-left: 16px;
            .psc-info17 {
              width: 154px;
              height: 24px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(40, 40, 40, 1);
              font-size: 16px;
              letter-spacing: 1.3333333730697632px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 24px;
              text-align: left;
            }
            .psc-info18 {
              width: 200px;
              height: 48px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(153, 153, 153, 1);
              font-size: 14px;
              letter-spacing: 1.1666666269302368px;
              font-family: AlibabaPuHuiTiR;
              line-height: 24px;
              text-align: left;
              margin-top: 4px;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
        .psc-mod14 {
          // z-index: 291;
          height: 452px;
          background-color: rgba(0, 255, 17, 0.05);
          width: 1920px;
          position: relative;
          .psc-mod19 {
            width: 1393px;
            height: 204px;
            margin-left: 200px;
            .psc-bd9 {
              width: 274px;
              height: 204px;
              .psc-img11 {
                width: 274px;
                height: 168px;
              }
              .psc-txt15 {
                width: 86px;
                height: 24px;
                display: block;
                overflow-wrap: break-word;
                color: rgba(40, 40, 40, 1);
                font-size: 16px;
                letter-spacing: 1.3333333730697632px;
                font-family: AlibabaPuHuiTiR;
                white-space: nowrap;
                line-height: 24px;
                text-align: left;
                margin: 12px 0 0 94px;
              }
            }
            .psc-label7 {
              width: 30px;
              height: 16px;
              margin-top: 77px;
            }
            .psc-bd10 {
              width: 274px;
              height: 204px;
              margin-left: 18px;
              .psc-pic9 {
                width: 274px;
                height: 168px;
              }
              .psc-txt16 {
                width: 69px;
                height: 24px;
                display: block;
                overflow-wrap: break-word;
                color: rgba(40, 40, 40, 1);
                font-size: 16px;
                letter-spacing: 1.3333333730697632px;
                font-family: AlibabaPuHuiTiR;
                white-space: nowrap;
                line-height: 24px;
                text-align: left;
                margin: 12px 0 0 103px;
              }
            }
            .psc-bd11 {
              width: 274px;
              height: 204px;
              margin-left: 48px;
              .psc-img12 {
                width: 274px;
                height: 168px;
              }
              .psc-txt17 {
                width: 69px;
                height: 24px;
                display: block;
                overflow-wrap: break-word;
                color: rgba(40, 40, 40, 1);
                font-size: 16px;
                letter-spacing: 1.3333333730697632px;
                font-family: AlibabaPuHuiTiR;
                white-space: nowrap;
                line-height: 24px;
                text-align: left;
                margin: 12px 0 0 103px;
              }
            }
            .psc-bd12 {
              width: 274px;
              height: 204px;
              margin-left: 48px;
              .psc-img13 {
                width: 274px;
                height: 168px;
              }
              .psc-word38 {
                width: 69px;
                height: 24px;
                display: block;
                overflow-wrap: break-word;
                color: rgba(40, 40, 40, 1);
                font-size: 16px;
                letter-spacing: 1.3333333730697632px;
                font-family: AlibabaPuHuiTiR;
                white-space: nowrap;
                line-height: 24px;
                text-align: left;
                margin: 12px 0 0 103px;
              }
            }
            .psc-label8 {
              width: 30px;
              height: 16px;
              margin: 77px 0 0 8px;
            }
            .psc-label9 {
              width: 30px;
              height: 16px;
              margin: 77px 0 0 16px;
            }
            .psc-label10 {
              width: 30px;
              height: 16px;
              margin: 77px 0 0 16px;
            }
            .psc-bd13 {
              width: 16px;
              height: 76px;
              margin: 112px 0 0 7px;
              .psc-bd14 {
                width: 16px;
                height: 30px;
                background: url(../../assets/images/procedure/greendownarrow.png)
                  0px 0px no-repeat;
              }
              .psc-bd15 {
                width: 16px;
                height: 30px;
                background: url(../../assets/images/procedure/greendownarrow.png)
                  0px 0px no-repeat;
                margin-top: 16px;
              }
            }
          }
          .psc-mod20 {
            width: 16px;
            height: 30px;
            margin-left: 1577px;
            .psc-group17 {
              width: 16px;
              height: 30px;
              background: url(../../assets/images/procedure/greendownarrow.png)
                0px 0px no-repeat;
            }
          }
          .psc-mod21 {
            width: 1211px;
            height: 170px;
            margin: 8px 0 0 511px;
            .psc-img14 {
              width: 274px;
              height: 168px;
            }
            .psc-pic20 {
              width: 274px;
              height: 168px;
              margin: 2px 0 0 18px;
            }
            .psc-pic21 {
              width: 274px;
              height: 168px;
              margin: 2px 0 0 18px;
            }
            .psc-img15 {
              width: 274px;
              height: 168px;
              margin-left: 79px;
            }
          }
          .psc-mod22 {
            width: 1015px;
            height: 24px;
            margin: 8px 0 8px 605px;
            .psc-word39 {
              width: 86px;
              height: 24px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(40, 40, 40, 1);
              font-size: 16px;
              letter-spacing: 1.3333333730697632px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 24px;
              text-align: left;
            }
            .psc-txt27 {
              width: 69px;
              height: 24px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(40, 40, 40, 1);
              font-size: 16px;
              letter-spacing: 1.3333333730697632px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 24px;
              text-align: left;
              margin-left: 221px;
            }
            .psc-txt28 {
              width: 69px;
              height: 24px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(40, 40, 40, 1);
              font-size: 16px;
              letter-spacing: 1.3333333730697632px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 24px;
              text-align: left;
              margin-left: 216px;
            }
            .psc-info19 {
              width: 69px;
              height: 24px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(40, 40, 40, 1);
              font-size: 16px;
              letter-spacing: 1.3333333730697632px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 24px;
              text-align: left;
              margin-left: 285px;
            }
          }
          .psc-label11 {
            z-index: 868;
            position: absolute;
            left: 826px;
            top: 77px;
            width: 30px;
            height: 16px;
          }
          .psc-icon7 {
            z-index: 871;
            position: absolute;
            left: 1112px;
            top: 77px;
            width: 30px;
            height: 16px;
          }
          .psc-label12 {
            z-index: 880;
            position: absolute;
            left: 785px;
            top: 321px;
            width: 30px;
            height: 16px;
          }
          .psc-label13 {
            z-index: 877;
            position: absolute;
            left: 1057px;
            top: 321px;
            width: 30px;
            height: 16px;
          }
          .psc-icon8 {
            z-index: 874;
            position: absolute;
            left: 1368px;
            top: 321px;
            width: 30px;
            height: 16px;
          }
        }
        .psc-mod15 {
          width: 1081px;
          height: 24px;
          margin: 20px 0 0 555px;
          .psc-label4 {
            width: 16px;
            height: 16px;
            margin-top: 4px;
          }
          .psc-word40 {
            width: 86px;
            height: 24px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(40, 40, 40, 1);
            font-size: 16px;
            letter-spacing: 1.3333333730697632px;
            font-family: AlibabaPuHuiTiR;
            white-space: nowrap;
            line-height: 24px;
            text-align: left;
            margin-left: 8px;
          }
          .psc-icon4 {
            width: 16px;
            height: 16px;
            margin: 4px 0 0 183px;
          }
          .psc-word41 {
            width: 137px;
            height: 24px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(40, 40, 40, 1);
            font-size: 16px;
            letter-spacing: 1.3333333730697632px;
            font-family: AlibabaPuHuiTiR;
            white-space: nowrap;
            line-height: 24px;
            text-align: left;
            margin-left: 8px;
          }
          .psc-icon5 {
            width: 16px;
            height: 16px;
            margin: 4px 0 0 130px;
          }
          .psc-word42 {
            width: 171px;
            height: 24px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(40, 40, 40, 1);
            font-size: 16px;
            letter-spacing: 1.3333333730697632px;
            font-family: AlibabaPuHuiTiR;
            white-space: nowrap;
            line-height: 24px;
            text-align: left;
            margin-left: 8px;
          }
          .psc-icon6 {
            width: 16px;
            height: 16px;
            margin: 4px 0 0 158px;
          }
          .psc-word43 {
            width: 120px;
            height: 24px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(40, 40, 40, 1);
            font-size: 16px;
            letter-spacing: 1.3333333730697632px;
            font-family: AlibabaPuHuiTiR;
            white-space: nowrap;
            line-height: 24px;
            text-align: left;
            margin-left: 8px;
          }
        }
        .psc-mod16 {
          width: 1137px;
          height: 72px;
          margin: 4px 0 0 579px;
          .psc-info20 {
            width: 200px;
            height: 72px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(153, 153, 153, 1);
            font-size: 14px;
            letter-spacing: 1.1666666269302368px;
            font-family: AlibabaPuHuiTiR;
            line-height: 24px;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .psc-txt18 {
            width: 200px;
            height: 72px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(153, 153, 153, 1);
            font-size: 14px;
            letter-spacing: 1.1666666269302368px;
            font-family: AlibabaPuHuiTiR;
            line-height: 24px;
            text-align: left;
            margin-left: 92px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .psc-txt19 {
            width: 200px;
            height: 72px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(153, 153, 153, 1);
            font-size: 14px;
            letter-spacing: 1.1666666269302368px;
            font-family: AlibabaPuHuiTiR;
            line-height: 24px;
            text-align: left;
            margin-left: 92px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .psc-word44 {
            width: 200px;
            height: 72px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(153, 153, 153, 1);
            font-size: 14px;
            letter-spacing: 1.1666666269302368px;
            font-family: AlibabaPuHuiTiR;
            line-height: 24px;
            text-align: left;
            margin-left: 153px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
      // .psc-group13 {
      //   z-index: 837;
      //   position: absolute;
      //   left: 563px;
      //   top: 602px;
      //   width: 1px;
      //   height: 64px;
      //   background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng91df46fb6d6d5e50498a64b147a5b13eac8abd6f0cb4eab1860691fe68fe1425)
      //     0px 0px no-repeat;
      // }
      // .psc-group14 {
      //   z-index: 838;
      //   position: absolute;
      //   left: 856px;
      //   top: 602px;
      //   width: 1px;
      //   height: 64px;
      //   background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng91df46fb6d6d5e50498a64b147a5b13eac8abd6f0cb4eab1860691fe68fe1425)
      //     0px 0px no-repeat;
      // }
      // .psc-group15 {
      //   z-index: 839;
      //   position: absolute;
      //   left: 1147px;
      //   top: 602px;
      //   width: 1px;
      //   height: 64px;
      //   background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng91df46fb6d6d5e50498a64b147a5b13eac8abd6f0cb4eab1860691fe68fe1425)
      //     0px 0px no-repeat;
      // }
      // .psc-group16 {
      //   z-index: 840;
      //   position: absolute;
      //   left: 1500px;
      //   top: 602px;
      //   width: 1px;
      //   height: 64px;
      //   background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng91df46fb6d6d5e50498a64b147a5b13eac8abd6f0cb4eab1860691fe68fe1425)
      //     0px 0px no-repeat;
      // }
    }
    .psc-info21 {
      width: 134px;
      height: 44px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(40, 40, 40, 1);
      font-size: 32px;
      letter-spacing: 2px;
      font-family: AlibabaPuHuiTiM;
      white-space: nowrap;
      line-height: 44px;
      text-align: left;
      margin: 120px 0 0 893px;
    }
    .psc-main24 {
      width: 1262px;
      height: 545px;
      margin: 60px 0 0 329px;
      .psc-outer15 {
        position: relative;
        width: 516px;
        height: 545px;
        overflow: hidden;
        // background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng6e222aab89903bdaa634f07c1c506ffbabc16b5e37cd74463cb2f5d44cc667c0)
        //   100% no-repeat;
        .psc-pic10 {
          position: absolute;
          left: 0;
          top: 0;
          width: 516px;
          height: 544px;
        }
      }
      .psc-outer16 {
        width: 645px;
        height: 545px;
        .psc-info22 {
          width: 159px;
          height: 29px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(40, 40, 40, 1);
          font-size: 21px;
          letter-spacing: 2px;
          font-family: AlibabaPuHuiTiM;
          white-space: nowrap;
          line-height: 29px;
          text-align: left;
        }
        .psc-txt20 {
          width: 218px;
          height: 20px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(153, 153, 153, 1);
          font-size: 14px;
          letter-spacing: 1.75px;
          font-family: AlibabaPuHuiTiR;
          white-space: nowrap;
          line-height: 20px;
          text-align: left;
          margin-top: 6px;
        }
        .psc-main25 {
          width: 84px;
          height: 4px;
          background-color: rgba(37, 184, 47, 1);
          margin-top: 10px;
        }
        .psc-word45 {
          width: 54px;
          height: 30px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(40, 40, 40, 1);
          font-size: 16px;
          letter-spacing: 2px;
          font-family: AlibabaPuHuiTiM;
          white-space: nowrap;
          line-height: 30px;
          text-align: left;
          margin-top: 20px;
        }
        .psc-paragraph3 {
          width: 645px;
          height: 90px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(102, 102, 102, 1);
          font-size: 14px;
          letter-spacing: 1.75px;
          font-family: AlibabaPuHuiTiR;
          line-height: 30px;
          text-align: left;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .psc-word46 {
          width: 89px;
          height: 30px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(40, 40, 40, 1);
          font-size: 16px;
          letter-spacing: 2px;
          font-family: AlibabaPuHuiTiM;
          white-space: nowrap;
          line-height: 30px;
          text-align: left;
          margin-top: 8px;
        }
        .psc-paragraph4 {
          width: 645px;
          height: 90px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(102, 102, 102, 1);
          font-size: 14px;
          letter-spacing: 1.75px;
          font-family: AlibabaPuHuiTiR;
          line-height: 30px;
          text-align: left;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .psc-main26 {
          width: 342px;
          height: 75px;
          margin: 32px 0 0 64px;
          .psc-info23 {
            width: 86px;
            height: 55px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(37, 184, 47, 1);
            font-size: 40px;
            letter-spacing: 2px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 55px;
            text-align: left;
          }
          .psc-info24 {
            width: 109px;
            height: 20px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(153, 153, 153, 1);
            font-size: 14px;
            letter-spacing: 1.75px;
            font-family: AlibabaPuHuiTiR;
            white-space: nowrap;
            line-height: 20px;
            text-align: left;
            margin: 55px 0 0 -84px;
          }
          .psc-layer10 {
            width: 17px;
            height: 31px;
            background: url(../../assets/images/procedure/hook.png) -1px -2px
              no-repeat;
            margin: 28px 0 0 -27px;
          }
          .psc-txt21 {
            width: 106px;
            height: 55px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(37, 184, 47, 1);
            font-size: 40px;
            letter-spacing: 2px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 55px;
            text-align: left;
            margin-left: 109px;
          }
          .psc-word47 {
            width: 63px;
            height: 20px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(153, 153, 153, 1);
            font-size: 14px;
            letter-spacing: 1.75px;
            font-family: AlibabaPuHuiTiR;
            white-space: nowrap;
            line-height: 20px;
            text-align: left;
            margin: 55px 0 0 -104px;
          }
          .psc-layer11 {
            width: 35px;
            height: 34px;
            background: url(../../assets/images/procedure/hookdown.png) -2px -1px
              no-repeat;
            margin: 24px 0 0 32px;
          }
        }
        .psc-main27 {
          height: 60px;
          background-color: rgba(37, 184, 47, 1);
          box-shadow: 0px 12px 24px 0px rgba(37, 184, 47, 0.2);
          margin-top: 41px;
          width: 180px;
          cursor: pointer;
          .psc-word48 {
            width: 85px;
            height: 29px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 21px;
            letter-spacing: 0.41999998688697815px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 29px;
            text-align: left;
            margin: 15px 0 0 47px;
          }
        }
      }
    }
    .psc-info25 {
      width: 181px;
      height: 50px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(40, 40, 40, 1);
      font-size: 36px;
      letter-spacing: 0.7200000286102295px;
      font-family: AlibabaPuHuiTiM;
      white-space: nowrap;
      line-height: 50px;
      text-align: left;
      margin: 120px 0 0 869px;
    }
    .psc-main28 {
      width: 1236px;
      height: 264px;
      margin: 32px 0 0 342px;
      .psc-pic11 {
        width: 340px;
        height: 264px;
      }
      .psc-label5 {
        width: 46px;
        height: 25px;
        margin: 144px 0 0 29px;
      }
      .psc-pic12 {
        width: 340px;
        height: 264px;
        margin-left: 33px;
      }
      .psc-label6 {
        width: 46px;
        height: 25px;
        margin: 144px 0 0 35px;
      }
      .psc-img16 {
        width: 340px;
        height: 264px;
        margin-left: 27px;
      }
    }
    .psc-main29 {
      width: 969px;
      height: 25px;
      margin-left: 476px;
      .psc-word49 {
        width: 73px;
        height: 25px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(39, 39, 39, 1);
        font-size: 18px;
        letter-spacing: 0.36000001430511475px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 25px;
        text-align: left;
      }
      .psc-word50 {
        width: 73px;
        height: 25px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(39, 39, 39, 1);
        font-size: 18px;
        letter-spacing: 0.36000001430511475px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 25px;
        text-align: left;
      }
      .psc-word51 {
        width: 73px;
        height: 25px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(39, 39, 39, 1);
        font-size: 18px;
        letter-spacing: 0.36000001430511475px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 25px;
        text-align: left;
      }
    }
    .psc-main30 {
      width: 1141px;
      height: 48px;
      margin: 6px 0 0 390px;
      .psc-paragraph5 {
        width: 245px;
        height: 48px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(102, 102, 102, 1);
        font-size: 14px;
        letter-spacing: 1.1666666269302368px;
        font-family: AlibabaPuHuiTiR;
        line-height: 24px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .psc-infoBox1 {
        width: 245px;
        height: 48px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(102, 102, 102, 1);
        font-size: 14px;
        letter-spacing: 1.1666666269302368px;
        font-family: AlibabaPuHuiTiR;
        line-height: 24px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .psc-paragraph6 {
        width: 245px;
        height: 48px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(102, 102, 102, 1);
        font-size: 14px;
        letter-spacing: 1.1666666269302368px;
        font-family: AlibabaPuHuiTiR;
        line-height: 24px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .psc-main31 {
      width: 1236px;
      height: 264px;
      margin: 40px 0 0 342px;
      .psc-img17 {
        width: 340px;
        height: 264px;
      }
      .psc-mod17 {
        width: 47px;
        height: 25px;
        background: url(../../assets/images/procedure/yellowleft.png)
          0px -5px no-repeat;
        margin: 144px 0 0 29px;
      }
      .psc-img18 {
        width: 340px;
        height: 264px;
        margin-left: 32px;
      }
      .psc-mod18 {
        width: 47px;
        height: 25px;
        background: url(../../assets/images/procedure/yellowleft.png)
          0px -5px no-repeat;
        margin: 144px 0 0 35px;
      }
      .psc-pic13 {
        width: 340px;
        height: 264px;
        margin-left: 26px;
      }
    }
    .psc-main32 {
      width: 969px;
      height: 25px;
      margin-left: 476px;
      .psc-info26 {
        width: 73px;
        height: 25px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(39, 39, 39, 1);
        font-size: 18px;
        letter-spacing: 0.36000001430511475px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 25px;
        text-align: left;
      }
      .psc-txt22 {
        width: 73px;
        height: 25px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(39, 39, 39, 1);
        font-size: 18px;
        letter-spacing: 0.36000001430511475px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 25px;
        text-align: left;
      }
      .psc-info27 {
        width: 73px;
        height: 25px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(39, 39, 39, 1);
        font-size: 18px;
        letter-spacing: 0.36000001430511475px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 25px;
        text-align: left;
      }
    }
    .psc-main33 {
      width: 1141px;
      height: 48px;
      margin: 6px 0 0 390px;
      .psc-paragraph7 {
        width: 245px;
        height: 48px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(102, 102, 102, 1);
        font-size: 14px;
        letter-spacing: 1.1666666269302368px;
        font-family: AlibabaPuHuiTiR;
        line-height: 24px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .psc-infoBox2 {
        width: 245px;
        height: 48px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(102, 102, 102, 1);
        font-size: 14px;
        letter-spacing: 1.1666666269302368px;
        font-family: AlibabaPuHuiTiR;
        line-height: 24px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .psc-paragraph8 {
        width: 245px;
        height: 48px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(102, 102, 102, 1);
        font-size: 14px;
        letter-spacing: 1.1666666269302368px;
        font-family: AlibabaPuHuiTiR;
        line-height: 24px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .psc-main34 {
      height: 527px;
      background-color: rgba(38, 38, 38, 1);
      margin-top: 120px;
      width: 1920px;
      .psc-outer17 {
        width: 1520px;
        height: 362px;
        margin: 81px 0 0 201px;
        .psc-outer18 {
          width: 1015px;
          height: 36px;
          margin-left: 128px;
          .psc-word52 {
            width: 38px;
            height: 36px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 18px;
            letter-spacing: 0.8571428656578064px;
            font-family: AlibabaPuHuiTiM;
            line-height: 36px;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .psc-word53 {
            width: 38px;
            height: 36px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 18px;
            letter-spacing: 0.8571428656578064px;
            font-family: AlibabaPuHuiTiM;
            line-height: 36px;
            text-align: left;
            margin-left: 156px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .psc-word54 {
            width: 38px;
            height: 36px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 18px;
            letter-spacing: 0.8571428656578064px;
            font-family: AlibabaPuHuiTiM;
            line-height: 36px;
            text-align: left;
            margin-left: 116px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .psc-word55 {
            width: 38px;
            height: 36px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 18px;
            letter-spacing: 0.8571428656578064px;
            font-family: AlibabaPuHuiTiM;
            line-height: 36px;
            text-align: left;
            margin-left: 116px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .psc-info28 {
            width: 87px;
            height: 36px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 21px;
            letter-spacing: 1px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 36px;
            text-align: left;
            margin-left: 388px;
          }
        }
        .psc-outer19 {
          width: 1138px;
          height: 56px;
          margin: 20px 0 0 128px;
          .psc-box4 {
            width: 73px;
            height: 56px;
            .psc-word56 {
              width: 73px;
              height: 24px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 24px;
              text-align: left;
            }
            .psc-word57 {
              width: 64px;
              height: 24px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 24px;
              text-align: left;
              margin-top: 8px;
            }
          }
          .psc-box5 {
            width: 67px;
            height: 56px;
            margin-left: 121px;
            .psc-info29 {
              width: 67px;
              height: 24px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 24px;
              text-align: left;
            }
            .psc-txt23 {
              width: 67px;
              height: 24px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 24px;
              text-align: left;
              margin-top: 8px;
            }
          }
          .psc-txt24 {
            width: 67px;
            height: 24px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 16px;
            letter-spacing: 0.761904776096344px;
            font-family: AlibabaPuHuiTiR;
            white-space: nowrap;
            line-height: 24px;
            text-align: left;
            margin-left: 87px;
          }
          .psc-word58 {
            width: 67px;
            height: 24px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 16px;
            letter-spacing: 0.761904776096344px;
            font-family: AlibabaPuHuiTiR;
            white-space: nowrap;
            line-height: 24px;
            text-align: left;
            margin-left: 87px;
          }
          .psc-txt25 {
            width: 210px;
            height: 36px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 16px;
            letter-spacing: 0.761904776096344px;
            font-family: AlibabaPuHuiTiR;
            line-height: 36px;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
            margin: 16px 0 0 359px;
          }
        }
        .psc-outer20 {
          width: 1262px;
          height: 88px;
          margin: 8px 0 0 128px;
          .psc-layer12 {
            width: 77px;
            height: 88px;
            .psc-word59 {
              width: 68px;
              height: 24px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 24px;
              text-align: left;
            }
            .psc-word60 {
              width: 77px;
              height: 24px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 24px;
              text-align: left;
              margin-top: 8px;
            }
            .psc-word61 {
              width: 67px;
              height: 24px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 24px;
              text-align: left;
              margin-top: 8px;
            }
          }
          .psc-layer13 {
            width: 67px;
            height: 56px;
            margin-left: 117px;
            .psc-word62 {
              width: 67px;
              height: 24px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 24px;
              text-align: left;
            }
            .psc-word63 {
              width: 67px;
              height: 24px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 24px;
              text-align: left;
              margin-top: 8px;
            }
          }
          .psc-layer14 {
            width: 334px;
            height: 84px;
            margin-left: 667px;
            .psc-info30 {
              width: 334px;
              height: 36px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              line-height: 36px;
              text-align: left;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .psc-word64 {
              width: 220px;
              height: 36px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              line-height: 36px;
              text-align: left;
              margin-top: 12px;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
        .psc-word65 {
          width: 68px;
          height: 24px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 16px;
          letter-spacing: 0.761904776096344px;
          font-family: AlibabaPuHuiTiR;
          white-space: nowrap;
          line-height: 24px;
          text-align: left;
          margin: 8px 0 0 128px;
        }
        .psc-word66 {
          width: 632px;
          height: 24px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 0.8);
          font-size: 14px;
          letter-spacing: 1.75px;
          font-family: AlibabaPuHuiTiR;
          white-space: nowrap;
          line-height: 24px;
          text-align: left;
          margin: 81px 0 0 443px;
        }
        .psc-outer21 {
          width: 1520px;
          height: 1px;
          // background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng36c95fcb9cd157277513064f87626c479109168afad811c1b82e7eec57e512e8)
          //   0px 0px no-repeat;
          margin-top: 16px;
        }
      }
    }
  }
  .psc-main35 {
    height: 396px;
    border: 1px solid rgba(245, 245, 245, 1);
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.06);
    width: 360px;
    position: absolute;
    left: 588px;
    top: 2903px;
    .psc-section3 {
      width: 97px;
      height: 33px;
      margin: 92px 0 0 132px;
      .psc-word67 {
        width: 97px;
        height: 33px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(40, 40, 40, 1);
        font-size: 24px;
        letter-spacing: 0.47999998927116394px;
        font-family: AlibabaPuHuiTiM;
        white-space: nowrap;
        line-height: 33px;
        text-align: center;
      }
    }
    .psc-section4 {
      width: 218px;
      height: 28px;
      margin: 36px 0 0 56px;
      .psc-block7 {
        width: 21px;
        height: 21px;
        background: url(../../assets/images/procedure/tick.png)
          0px 0px no-repeat;
        margin-top: 4px;
      }
      .psc-word68 {
        width: 188px;
        height: 28px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(102, 102, 102, 1);
        font-size: 16px;
        letter-spacing: 1.3333333730697632px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 28px;
        text-align: center;
      }
    }
    .psc-section5 {
      width: 270px;
      height: 28px;
      margin: 24px 0 0 56px;
      .psc-bd16 {
        width: 21px;
        height: 22px;
        background: url(../../assets/images/procedure/tick.png)
          0px 0px no-repeat;
        margin-top: 4px;
      }
      .psc-txt26 {
        width: 240px;
        height: 28px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(102, 102, 102, 1);
        font-size: 16px;
        letter-spacing: 1.3333333730697632px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 28px;
        text-align: center;
      }
    }
    .psc-section6 {
      width: 235px;
      height: 28px;
      margin: 24px 0 103px 56px;
      .psc-outer28 {
        width: 21px;
        height: 21px;
        background: url(../../assets/images/procedure/tick.png)
          0px 0px no-repeat;
        margin-top: 4px;
      }
      .psc-info31 {
        width: 205px;
        height: 28px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(102, 102, 102, 1);
        font-size: 16px;
        letter-spacing: 1.3333333730697632px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 28px;
        text-align: center;
      }
    }
  }
  .psc-main35:hover {
    background-color: rgba(37, 184, 47, 1);
    box-shadow: 0px 20px 40px 0px rgba(37, 184, 47, 0.24);
    .psc-word67 {
      color: rgba(255, 255, 255, 1);
    }
    .psc-word68, .psc-txt26, .psc-info31 {
      color: rgba(255, 255, 255, 1);
    }
    .psc-block7, .psc-bd16, .psc-outer28 {
      background: url(../../assets/images/procedure/whitetick.png);
    }
    .psc-main15 {
        width: 259px;
          height: 165px;
          background: url(../../assets/images/back/greencloth.png) 100%
            no-repeat;
          margin: -83px 0 0 56px;
        }
  }
}
</style>
<style src="../../assets/css/common.css" />